import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import { CareerPathProps } from "./Tact.d"
import { Image } from "../../atoms/Image"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const CareerPath: React.FC<CareerPathProps> = ({
  heading,
  careerMilestones,
}) => {
  const responsive = {
    0: { items: 1 },
    768: { items: 2 },
    1024: { items: 3 },
    1440: { items: 4 },
  }

  const [items, setItems] = useState([])
  const { _, language } = useContext(LanguageContext)
  useEffect(() => {
    setItems(
      careerMilestones?.map((milestone: any) => (
        <div
          css={[
            tw`font-semibold text-lg text-center mx-12 lg:(text-2xl) 2xl:(mx-10)`,
          ]}
        >
          <span css={[tw`w-full flex justify-center `]}>
            <Image
              imageData={milestone?.image?.image}
              css={[tw`h-32 m-0 md:(h-32) object-contain`]}
            />
          </span>
          <span css={[tw`block uppercase mb-3`]}>{milestone.name}</span>
          <span css={[tw`block font-light text-sm tracking-widest`]}>
            {language === "en" ? "Experience" : "Experiencia"}
          </span>
          <span css={[tw`block font-light tracking-widest`]}>
            {milestone.experience}
          </span>
          <span css={[tw`block font-light text-base mt-3`]}>
            {milestone.description}
          </span>
        </div>
      ))
    )
  }, [careerMilestones])
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute -left-6 top-[45%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:bg-gunmetalHover focus-visible:(bg-gunmetalHover)`,
          tw`lg:(left-0)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
        onClick={() =>
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "left arrow",
          })
        }
      >
        <Icon.Chevron direction="left" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const renderNextButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute -right-6 top-[45%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:(bg-gunmetalHover) focus-visible:(bg-gunmetalHover)`,
          tw`lg:(right-0)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
        onClick={() =>
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "right arrow",
          })
        }
      >
        <Icon.Chevron direction="right" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  return (
    <section aria-label={`${heading}` + " Section"}>
      <div css={[tw`p-8`, tw`lg:(max-w-desktop mx-auto p-0 my-20)`]}>
        <div
          css={[
            tw`uppercase text-center font-light mb-10 tracking-widest line-height[2] text-3xl lg:(mb-10 w-60 mx-auto)`,
          ]}
        >
          <span>{heading}</span>
        </div>
        <div css={[tw`max-w-desktop my-10 relative`]}>
          <AliceCarousel
            mouseTracking
            items={items}
            disableDotsControls
            responsive={responsive}
            paddingLeft={20}
            paddingRight={20}
            controlsStrategy="alternate"
            renderPrevButton={renderPrevButton}
            renderNextButton={renderNextButton}
          />
        </div>
      </div>
    </section>
  )
}

export default CareerPath
