import React from "react"
import tw from "twin.macro"
import { Image } from "../../atoms/Image"

import { TactBenefitsProps } from "./Tact.d"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const TactBenefits: React.FC<TactBenefitsProps> = ({
  benefits,
  backgroundImage,
}) => {
  return (
    <div
      css={[
        tw`bg-gray-900 relative h-auto items-center flex-row overflow-hidden md:(h-[680px])`,
      ]}
    >
      <Image
        imageData={backgroundImage?.image}
        css={[
          tw`relative top-0`,
          `z-index: 0;`,
          tw`md:(absolute w-auto top-0 h-[680px] opacity-20 object-fit[cover])`,
        ]}
      />
      <div
        css={[
          tw` relative max-w-desktop mx-auto py-12 px-8 text-white lg:(py-32) 2xl:(px-0)`,
          `z-index: 10;`,
        ]}
      >
        <h3
          css={[
            tw`font-light line-height[1.2] text-3xl tracking-widest px-8 mb-20 lg:(px-0)`,
          ]}
        >
          Benefits
        </h3>
        <div
          css={[
            tw`grid overflow-hidden grid-cols-1 gap-10 mx-10 md:(grid-cols-2 gap-x-32) lg:(grid-cols-4 gap-10) xl:(gap-20)`,
          ]}
        >
          {benefits?.map(
            (benefit: { heading: string; description: string }) => (
              <div
                css={[
                  tw`uppercase text-lg border-t-2 border-red-500 text-center px-6 pt-10`,
                ]}
              >
                <span css={[tw``]}>{benefit.heading}</span>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default TactBenefits
