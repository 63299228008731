import React, { useEffect, useState } from "react"
import tw from "twin.macro"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

import { TestimonialsProps } from "./Tact.d"
import Icon from "../../atoms/Icon"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const TactBenefits: React.FC<TestimonialsProps> = ({
  heading,
  testimonials,
}) => {
  const responsive = {
    0: { items: 1 },
    768: { items: 2 },
    1024: { items: 3 },
  }

  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(
      testimonials?.map((testimonial: { quote: string }) => (
        <div
          css={[
            tw`font-semibold text-gray-50 text-lg text-center lg:(text-2xl) mx-14`,
          ]}
        >
          "{testimonial.quote}"
        </div>
      ))
    )
  }, [testimonials])

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute -left-6 top-[45%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full transition-all hover:bg-gray-400 focus-visible:(bg-gray-400)`,
          tw`lg:(-left-10)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="left" color="black" css={[tw`h-5`]} />
      </button>
    )
  }

  const renderNextButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute -right-6 top-[45%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full transition-all hover:(bg-gray-400) focus-visible:(bg-gray-400)`,
          tw`lg:(-right-10)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="right" color="black" css={[tw`h-5`]} />
      </button>
    )
  }

  return (
    <div css={[tw`bg-gray-800 pb-10`]}>
      <section
        aria-label="Testimonials Section"
        css={[
          tw`max-w-desktop mx-auto pt-10 mb-10 relative px-8 2xl:( pt-0 p-0)`,
        ]}
      >
        <h3
          css={[
            tw`line-height[1.2] text-3xl text-center text-gray-50 px-8 tracking-widest mb-10 font-light lg:(p-0 mb-10)`,
          ]}
        >
          {heading}
        </h3>

        <AliceCarousel
          mouseTracking
          items={items}
          disableDotsControls
          responsive={responsive}
          paddingLeft={10}
          paddingRight={10}
          controlsStrategy="alternate"
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
        />
      </section>
    </div>
  )
}

export default TactBenefits
