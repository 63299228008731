import React from "react"
import tw from "twin.macro"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { WhyTactProps } from "./Tact.d"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const WhyTact: React.FC<WhyTactProps> = ({ heading, reasons }) => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  return (
    <section
      aria-label="Why Toyota Apprenticeship Career Training Section"
      css={[tw`bg-gray-50 lg:(bg-white mt-10)`]}
    >
      <div css={[tw`p-8 lg:(max-w-desktop mx-auto my-20) 2xl:(p-0)`]}>
        <div
          css={[
            tw`font-light mt-10 mb-10 tracking-widest line-height[2] text-3xl lg:(w-1/3 mb-10)`,
          ]}
        >
          {heading}
        </div>
        <div
          css={[
            tw`grid overflow-hidden grid-cols-1 gap-10 px-8 lg:(grid-cols-4 gap-24)`,
          ]}
        >
          {reasons.map((reason: any) => {
            return (
              <div>
                <div>
                  <div
                    css={tw`font-semibold line-height[1.2] text-2xl lg:(h-32) xl:(h-28)`}
                  >
                    {reason.heading}
                  </div>
                  <div css={[tw`text-lg`]}>{reason.description}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default WhyTact
