import { graphql, PageProps } from "gatsby"
import React, { useContext, useEffect, useRef } from "react"
import { Layout } from "../components/atoms/Layout"
import { SEO } from "../components/atoms/SEO"
import FAQ from "../components/molecules/FAQ/FAQ"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import TactHeader from "../components/organisms/Tact/Header"
import WhyTact from "../components/organisms/Tact/WhyTact"
import TactBenefits from "../components/organisms/Tact/TactBenefits"
import TactVideoSection from "../components/organisms/Tact/TactVideoSection"
import CareerPath from "../components/organisms/Tact/CareerPath"
import Testimonials from "../components/organisms/Tact/Testimonials"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { LocationContext } from "../contexts/Location"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import { ContactContext } from "../contexts/Contact"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import { Brochure, ToyotaCare } from "../components/organisms/Series"
import { RetentionSalesEventHero } from "../components/molecules/RetentionSalesEventHero"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"
import TactContactDealer from "../components/molecules/ContactDealer/TactContactDealer"
import {
  closeTactContactDealerModal,
  openTactContactDealerModal,
} from "../contexts/Contact/actions"

const TactPage: React.FC<PageProps> = ({ data, location }: any) => {
  const { page } = data
  const [{ isTactContactDealerModalOpen }, dispatch] =
    useContext(ContactContext)

  // Tealium
  const { handleTealView } = useTealiumView()
  const [{ dealers }] = useContext(LocationContext)
  const [isOutOfArea] = useTealiumNoZip()

  const dealerCodes = dealers
    ?.filter(dealer => dealer.TactUrl)
    .map(dealer => dealer.DealerCode)
  const dealerNames = dealers
    ?.filter(dealer => dealer.TactUrl)
    .map(dealer => dealer.Name)
  const firstLoad = useRef(true)

  useEffect(() => {
    // Initial view event for out of area users, who have not yet set a zip code
    if (isOutOfArea && firstLoad.current) {
      firstLoad.current = false
      handleTealView({
        tealium_event: "tact_homepage",
        page_name: "tact homepage",
        page_type: "tact",
        search_results: 0,
        dealer_name: "NOT SET",
        dealer_code: "NOT SET",
      })
    }

    if (firstLoad.current && dealerCodes && dealerNames) {
      firstLoad.current = false
      handleTealView({
        tealium_event: "tact_homepage",
        page_name: "tact homepage",
        page_type: "tact",
        search_results: dealerNames.length,
        dealer_name: dealerNames,
        dealer_code: dealerCodes,
      })
    }
  }, [dealers, isOutOfArea])

  const ctaBoxData = page?.content?.find(
    (section: any) => section?._type === "callToActionBox"
  )

  return (
    <Layout tealium={{ label: "tactPage" }}>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <SanitySections data={page} />
      <TactContactDealer
        modalIsOpen={isTactContactDealerModalOpen}
        setModalIsOpen={open => {
          if (open) {
            dispatch(openTactContactDealerModal())
          } else {
            dispatch(closeTactContactDealerModal())
          }
        }}
      />
    </Layout>
  )
}

const SanitySections = ({ data }: any): JSX.Element => {
  const retentionSalesEventData = data?.content?.find(
    (section: any) => section._type === "salesEventHeroPageBuilder"
  )?.salesEvent
  const retentionSalesEventHeroData = retentionSalesEventData?.salesEventActive
    ? retentionSalesEventData?.hero
    : null
  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "header":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero {...retentionSalesEventHeroData} />
              )
            }
            return (
              <TactHeader
                {...section}
                headerTextSize={"small"}
                descriptionTextSize={"large"}
              />
            )
          case "partsAndServiceHero":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero {...retentionSalesEventHeroData} />
              )
            }
            return <PartAndServiceHero {...section} />
          case "faqSection":
            return <FAQ {...section} title={"TACT"} />
          case "dealersNearYouSection":
            return (
              <DealersNearYou
                type="tact"
                {...section}
                ctaAnalyticsId="apply now"
              />
            )
          case "whyTactSection":
            return <WhyTact {...section} />
          case "benefitsSection":
            return <TactBenefits {...section} />
          case "videoSection":
            return <TactVideoSection {...section} />
          case "testimonials":
            return <Testimonials {...section} />
          case "careerPathSection":
            return <CareerPath {...section} />
          case "imageBlocksWithDescriptionSection":
            return <ImageBlocksWithDescription section={section} />
          case "salesEventImageWithTextPageBuilder":
            if (!section?.salesEvent?.salesEventActive) return null
            return (
              <ToyotaCare
                {...section?.salesEvent?.imageWithText}
                imagePosition={section.imagePosition}
              />
            )
          case "brochure":
            return <Brochure {...section} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query TactPageQuery($id: String) {
    page: sanityPageTact(id: { eq: $id }) {
      id
      language
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      content {
        ...HeaderData
        ...FaqSectionData
        ...DealersNearYouData
        ...WhyTactSectionData
        ...BenefitsSectionData
        ...VideoSectionData
        ...TestimonialsSectionData
        ...CareerPathSectionData
        ...CallToActionBoxData
        ...ImageBlocksWithDescriptionData
        ...RetentionSalesEventImageWithTextData
        ...RetentionSalesEventHeroData
        ...PartsAndServiceHeroData
        ...BrochureData
      }
    }
  }
`

export default TactPage
