import React, { useContext } from "react"
import tw from "twin.macro"
import { TactHeaderProps } from "./Tact.d"
import { Image } from "../../atoms/Image"
import { parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const TactHeader: React.FC<TactHeaderProps> = ({
  headerHeading,
  headerBackground,
  headerSubHeading,
  headerTextSize = "large",
  descriptionTextSize = "small",
  hasCTABox,
}) => {
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <section
      aria-label="Toyota Apprenticeship Career Training Hero Section"
      css={[
        tw`flex flex-col h-auto bg-[#ccc] relative`,
        tw`md:(items-center justify-center flex-row h-[680px])`,
        tw`lg:(items-center justify-start flex-row h-[680px])`,
      ]}
    >
      {headerBackground?.asset && (
        <Image
          imageData={headerBackground}
          css={[
            tw`relative top-0 w-full h-[300px] object-cover`,
            "z-index: 0;",
            tw`md:(h-[680px] absolute top-0 object-cover)`,
          ]}
        />
      )}
      <div
        css={[
          tw`ml-0 w-full z-20 bg-white p-6`,
          tw`md:(p-10 max-w-[620px] rounded-2xl bg-opacity-90)`,
          tw`lg:(p-10 w-auto ml-40 rounded-2xl bg-opacity-90)`,
        ]}
      >
        <h1
          css={[
            tw`text-xl font-semibold mb-4 flex items-center`,
            headerTextSize === "large" && tw`md:(text-4xl mb-4)`,
            headerTextSize === "small" && tw`md:(text-xl mb-4)`,
          ]}
        >
          {parseDisclaimerBlocks(headerHeading, selection =>
            modalDispatch(toggleDisclaimersModal(selection))
          )}
        </h1>
        <h4
          css={[
            tw`text-base tracking-widest font-light leading-normal mb-0`,
            descriptionTextSize === "large" && tw`md:(text-2xl mb-6 w-96)`,
            descriptionTextSize === "small" && tw`md:(text-lg mb-6 w-96)`,
          ]}
        >
          {parseDisclaimerBlocks(headerSubHeading, selection =>
            modalDispatch(toggleDisclaimersModal(selection))
          )}
        </h4>
      </div>
    </section>
  )
}

export default TactHeader
